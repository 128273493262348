import { createHash } from 'node:crypto'

import Hashids from 'hashids' // not really random, but thats enough

const hashIds = new Hashids()
const dateBias = 1571011200000 // 2019-10-14

export const shortRandomName = () =>
  hashIds.encode(
    new Date().valueOf() - dateBias + Math.floor(Math.random() * 100),
  )

// For browser usage, we have only a subset of algorithms available in the polyfill: https://www.npmjs.com/package/crypto-browserify
export const hashDataStringMD5 = (data: string) =>
  createHash('md5').update(data).digest('hex')
